import { defineStore } from 'pinia'
import { computed } from 'vue'
import { NavigationHookAfter, RouteLocationNormalized } from 'vue-router'

const useRouteHistoryStore = defineStore('routeHistory', {
  state: (): {
    lastRoute: RouteLocationNormalized | null
  } => ({
    lastRoute: null,
  }),
})

export const trackLastRoute: NavigationHookAfter = (_to, from) => {
  const store = useRouteHistoryStore()
  store.lastRoute = from
}

export const useRouterHistory = () => {
  const store = useRouteHistoryStore()

  return {
    lastRoute: computed(() => store.lastRoute),
  }
}
